import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"
//import Img from "gatsby-image"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allLanueva.edges

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="All posts" />
      {posts.map(({ node }) => {
        const title = node.titulo || node.titulo
        return (
          <article key={node._id}>
            <header>
              <h3
                style={{
                  marginBottom: rhythm(1 / 4),
                }}
              >
                <img src={node.image_home} alt="" />
                <Link style={{ boxShadow: `none` }} to={node._id}>
                  {title}
                
                
                </Link>
              </h3>

            </header>
            <section>
              <p
                dangerouslySetInnerHTML={{
                  __html: node.introHTML || node.introNotaHTML,
                }}
              />
            </section>
          </article>
        )
      })}
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allLanueva{
      edges {
        node {
          _id
          introNotaHTML
          titulo
        }
      }
    }
  }
`
